import React, { createRef, useRef, useState } from 'react'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player/file'

import Button from 'react-bootstrap/Button'

import { FaPlay } from 'react-icons/fa'

import { useViewportDimensions } from 'utils/use-viewport-dimensions'

const Intro = ({ children, phase }) => {
  if (phase !== 'intro') return null
  return (
    <>
      {children}
      <div>&nbsp;</div>
    </>
  )
}

const Outro = ({ children, phase, workid }) => {
  if (phase !== 'outro') return null
  return (
    <>
      {children}
      <div className="w-auto my-3">
        <Button className="m-1" variant="outline-secondary" size="sm" href={`/node/${workid}/`}>
          Play Again
        </Button>
        <Button className="m-1" variant="outline-secondary" size="sm" href={`/node/`}>
          More Nodes
        </Button>
        <Button className="m-1 py-auto" variant="outline-secondary" size="sm" href="/">
          Portal
        </Button>
      </div>
    </>
  )
}

const OverlayComponents = { Intro, Outro }

const VideoView = ({ workid, video, poster, overlay, title }) => {
  const instanceRef = createRef()
  const playerRef = createRef()
  const [currentPhase, setCurrentPhase] = useState('intro')
  const [isOutro, setIsOutro] = useState(true)
  const minVideoDim = 400 // for #main min-height=400
  const maxVideoDim = 1080 // for 1x1
  const { height, width } = useViewportDimensions()
  const videoDim = Math.max(minVideoDim, Math.min(maxVideoDim, Math.min(height, width)))
  if (!!!videoDim) return null
  const posterSrc = `https://imagedelivery.net/_3JEbidtzC6H54nC6DnRtQ/${poster}/1080x1080`
  const videoSrc = `https://videodelivery.net/${video}/manifest/video.m3u8`
  const hasOverlay = overlay?.length > 0
  const overlayDuration = hasOverlay ? 10 : 0
  const variants = {
    visible: { opacity: 1 },
    muted: { opacity: 0.1 },
    hidden: { opacity: 0 },
  }

  const handlePlay = () => {
    // setCurrentPhase('playing')
    playerRef?.current?.play()
  }

  const onPlayerClick = (event) => {
    playerRef.current.controls = true
    event.preventDefault()
  }

  const onPlayerEnded = () => {
    setCurrentPhase('outro')
  }

  const onPlayerStart = () => {
    setCurrentPhase('playing')
  }

  const onPlayerReady = () => {
    const player = instanceRef.current.getInternalPlayer()
    player.controls = false
    player.disablePictureInPicture = true
    player.playsinline = true
    player.poster = posterSrc
    playerRef.current = player
  }

  const renderOverlay = () => {
    if (!hasOverlay || currentPhase == 'playing') return null
    return (
      <motion.div
        className="position-absolute top-0 start-0 h-100 w-100 d-flex justify-content-center text-white"
        variants={variants}
        animate={currentPhase == 'outro' ? 'visible' : 'hidden'}
        initial={{ opacity: currentPhase == 'intro' ? 1 : 0 }}
        transition={{ delay: currentPhase == 'intro' ? overlayDuration : 0, duration: 2 }}
      >
        <div className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center text-center">
          <div className="h-100 w-75 d-flex flex-column align-items-center justify-content-evenly text-center">
            <MDXProvider components={OverlayComponents}>
              <MDXRenderer phase={currentPhase} workid={workid}>{overlay}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </motion.div>
    )
  }

  const renderPlayButton = () => {
    if (currentPhase != 'intro') return null
    return (
      <motion.div
        className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center text-center text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: overlayDuration / 2, duration: 1 }}
      >
        <FaPlay className="video-play o-60" />
      </motion.div>
    )
  }

  const renderPlayButtonHover = () => {
    if (currentPhase != 'intro') return null
    return (
      <motion.div
        className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center text-center text-white"
        style={{ cursor: 'pointer' }}
        onClick={handlePlay}
        initial={{ opacity: 0 }}
        whileHover={{ opacity: 1 }}
      >
        <FaPlay className="video-play" />
      </motion.div>
    )
  }

  const renderPlayer = () => {
    return (
      <motion.div
        className="position-absolute top-0 start-0 h-100 w-100"
        variants={variants}
        animate={currentPhase == 'outro' ? 'hidden' : currentPhase == 'intro' ? 'muted' : 'visible'}
        initial={{ opacity: currentPhase == 'intro' ? 0 : 1 }}
        transition={{ duration: 2 }}
      >
        <ReactPlayer
          url={videoSrc}
          poster={posterSrc}
          width={videoDim}
          height={videoDim}
          ref={instanceRef}
          onClick={onPlayerClick}
          onEnded={onPlayerEnded}
          onReady={onPlayerReady}
          onStart={onPlayerStart}
        />
      </motion.div>
    )
  }

  const renderPoster = () => {
    if (currentPhase == 'playing') return null
    return (
      <div className="position-absolute top-0 start-0 h-100 w-100 o-40">
        <img src={posterSrc} alt="" style={{ width: videoDim, height: videoDim }} />
      </div>
    )
  }

  return (
    <div id="video" className="mx-auto position-relative" style={{ width: videoDim, height: videoDim }}>
      {renderPoster()}
      {renderPlayer()}
      {renderOverlay()}
      {renderPlayButton()}
      {renderPlayButtonHover()}
    </div>
  )
}

export default VideoView
