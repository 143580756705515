import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Carousel from 'components/carousel'
import { useWorkNodeImage } from 'utils/use-work-node-image'

const ImageCarouselView = ({ workid, images, title }) => {
  return (
    <Carousel>
      {images.map((image) => (
        <GatsbyImage image={getImage(useWorkNodeImage(workid, image))} alt={title} />
      ))}
    </Carousel>
  )
}

export default ImageCarouselView
