import { useEffect, useState } from 'react'

const isBrowser = typeof window !== 'undefined'

export const useViewportDimensions = () => {
  const [viewportDim, setViewportDim] = useState({
    height: undefined,
    width: undefined,
  })

  useEffect(() => {
    function handleResize() {
      const headerHeight = 60
      const footerHeight = 50
      setViewportDim({
        height: (document.documentElement.clientHeight || document.body.clientHeight) - (headerHeight + footerHeight),
        width: document.documentElement.clientWidth || document.body.clientWidth,
      })
    }

    // debounce
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        handleResize()
      }, 150)
    }
    window.addEventListener('resize', resizeListener)

    // initialize
    handleResize()

    return () => window.removeEventListener('resize', resizeListener)
  }, [])

  return { ...viewportDim }
}
