import React from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import removeMarkdown from 'remove-markdown'

import Container from 'react-bootstrap/Container'

import Meta from 'components/meta'
import WrapperImage from 'components/wrapper-image'
import ImageCarouselView from 'components/image-carousel-view'
import ImageSoloView from 'components/image-solo-view'
import MediaCarouselView from 'components/media-carousel-view'
import VideoView from 'components/video-view'

import './node-view.scss'

const GlobalComponents = {
  Container,
}
interface Props {
  location: Location
  data: unknown
}

const NodeView = ({ location, pageContext, data: { mdx } }) => {
  const { body, mdxAST, id, slug, frontmatter } = mdx
  const hasBodyContent = mdxAST?.children?.length > 0
  const workid = slug.replace(/\/$/, '')
  const { image, images, slides, tagline, title, video, view } = frontmatter
  const nodeTitle = `${title} in Your Ocean, My Ocean`

  const prepareBodyContent = () => {
    return mdxAST?.children?.length < 2 ? null : body
  }

  const renderView = () => {
    switch (view) {
      case 'image':
        return <ImageSoloView workid={workid} image={image} title={nodeTitle} />
      case 'images':
        return <ImageCarouselView workid={workid} images={images} title={nodeTitle} />
      case 'media':
        return <MediaCarouselView workid={workid} slides={slides} title={nodeTitle} />
      case 'video':
        return (
          <VideoView workid={workid} video={video} poster={image} overlay={prepareBodyContent()} title={nodeTitle} />
        )
    }
    return (
      <MDXProvider components={GlobalComponents}>
        <MDXRenderer>{prepareBodyContent()}</MDXRenderer>
      </MDXProvider>
    )
  }

  return (
    <WrapperImage background="yomo-node-bg.jpg" effect="duotone" backgroundClass="o-50" location={location}>
      <Meta title={title} description={removeMarkdown(tagline)} />
      <div id="node-view" className="node-view h-100 d-flex flex-column align-items-center justify-content-center">
        <div className="w-100">{renderView()}</div>
      </div>
    </WrapperImage>
  )
}

export const pageQuery = graphql`
  query MdxNodeViewQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      slug
      body
      mdxAST
      frontmatter {
        image
        images
        note
        slides
        tagline
        title
        video
        view
      }
    }
  }
`

export default NodeView
