import React from 'react'

import Carousel from 'components/carousel'

const MediaCarouselView = ({ workid, slides, title }) => {
  return (
    <Carousel>
      {slides.map((slide, index) => (
        <div>{slide}</div>
      ))}
    </Carousel>
  )
}

export default MediaCarouselView
