import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { useViewportDimensions } from 'utils/use-viewport-dimensions'
import { useWorkNodeImage } from 'utils/use-work-node-image'

const ImageSoloView = ({ workid, image, title }) => {
  const minImageDim = 400 // for #main min-height=400
  const maxImageDim = 1080 // for 1x1
  const { height, width } = useViewportDimensions()
  const imageDim = Math.max(minImageDim, Math.min(maxImageDim, Math.min(height, width)))
  return (
    <div id="image" className="my-3 d-flex align-items-center justify-content-center">
      <div style={{ height: imageDim, width: imageDim }}>
        <GatsbyImage image={getImage(useWorkNodeImage(workid, image))} alt={title} />
      </div>
    </div>
  )
}

export default ImageSoloView
