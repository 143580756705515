import { graphql, useStaticQuery } from 'gatsby'

export const useWorkNodeImage = (workid, image) => {
  const relativePath = `${workid}/${image}`
  const {
    data: { nodes },
  } = useStaticQuery(
    graphql`
      query WorkNodeImagesQuery {
        data: allFile(
          filter: { sourceInstanceName: { eq: "works" }, internal: { mediaType: { regex: "/^image/" } } }
          sort: { fields: relativePath }
          limit: 9999
        ) {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1080)
            }
          }
        }
      }
    `
  )
  const node = nodes.find((n) => n.relativePath == relativePath)
  return node?.childImageSharp
}
